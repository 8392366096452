import { useEffect, useRef, useState } from 'react'
import moment, { getDurationFromStartAndEndTime } from '.'

export const useActiveMomentDuration = (
  startTime: number,
  endTime: number,
  unit: moment.unitOfTime.DurationConstructor = 'seconds',
  isActive?: boolean
) => {
  // For completed drills, calculate once so it doesn't re-render
  if (!isActive) {
    return getDurationFromStartAndEndTime(startTime, endTime, unit)
  }

  // Only use interval for active drills
  const [duration, setDuration] = useState<moment.Duration>(
    getDurationFromStartAndEndTime(startTime, endTime, unit)
  )

  const intervalRef = useRef(null)

  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current)

    // Only set up interval for active drills
    if (isActive) {
      intervalRef.current = setInterval(() => {
        setDuration(getDurationFromStartAndEndTime(startTime, endTime, unit))
      }, 1000)

      return () => clearInterval(intervalRef.current)
    }
  }, [startTime, endTime, unit])

  return duration
}

export const useFormattedDuration = (startTime: number, endTime: number) => {
  const duration = useActiveMomentDuration(startTime, endTime)
  return {
    ...duration,
    formatted: `${String(duration.hours()).padStart(2, '0')}:${String(
      duration.minutes()
    ).padStart(2, '0')}:${String(duration.seconds()).padStart(2, '0')}`
  }
}
